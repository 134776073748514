import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/scss/main.scss'


const apps = createApp(App)
.use(router)
.mount('#app')


// app.config.globalProperties.someProperty = 'some value';