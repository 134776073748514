<template>
  <!-- ====== Footer Start ====== -->
  <footer class="ud-footer wow fadeInUp" data-wow-delay=".5s">
  <div class="ud-footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <ul class="ud-footer-bottom-left">
                <li>
                  <router-link to="/" class="" >Home</router-link>
                </li>
                <li>
                  <router-link to="privacy_policy" class="" >Privacy Policy</router-link>
                </li>
                
                <li>
                  <router-link to="terms_condition" class="" >Terms and Conditions</router-link>
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <p class="ud-footer-bottom-right">
                Copyright &copy; 2023. All Right Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
  <!-- ====== Footer End ====== -->

  <!-- ====== Back To Top Start ====== -->
  <a href="#" class="back-to-top">
    <i class="lni lni-chevron-up"> </i>
  </a>
</footer>
  <!-- ====== Back To Top End ====== -->
</template>
<script>
export default {
  name: 'HomeFooter',

}
</script>