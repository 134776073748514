<template >
<!-- <HomeHeader /> -->

<!-- 
  <nav class="navbar navbar-light bg-light"> -->
  <!-- <router-link class="text"  to="/">Home</router-link>
  <router-link class="text"  to="/Contact">contact</router-link>
  <router-link class="text"  to="/Service">service</router-link>
  <router-link class="text"  to="/Gallery">gallery</router-link>
  <router-link class="text"  to="/Blog">blog</router-link>
  <router-link class="text"  to="/Search">search</router-link>  -->
   <!--  -->
  <router-view></router-view>
  <HomeFooter />
  <!-- </nav> -->
</template>

<script>
// import HomeHeader from "./components/HomeHeader"
import HomeFooter from "./components/HomeFooter"
export default {
  name: 'App',
   components: {
  //      HomeHeader,
        HomeFooter
        
     }
  }

</script>

<style>




</style>
