
import {createWebHistory, createRouter } from "vue-router";

 
const routes =  [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "Home" */ './view/Home')
    },
    {
      path: '/terms_condition',
      name: 'Term_service',
      component: () => import(/* webpackChunkName: "Service" */ './view/Term_service')
    },
    {
      path: '/privacy_policy',
      name: 'Privacy_policy',
      component: () => import(/* webpackChunkName: "Service" */ './view/Privacy_policy')
    },
    {
      path: '/:service',
      name: 'Service',
      component: () => import(/* webpackChunkName: "Service" */ './view/Services')
    },
]

const router = createRouter({
  
  history: createWebHistory(),
  routes, 
});
export default router;